/* app.css */


/* Navbar */

.navbar {
    background-color: black;
    padding: 10px;
    color: white;
    display: flex;
    justify-content: center;
}

.user-address {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.address-box {
    border: 1px solid #F2A900;
    border-radius: 5px;
    padding: 5px;
}

.connect-button {
    background-color: #F2A900;
    border-radius: 5px;
    color: white;
    border: none;
    outline: none;
    padding: 5px 10px;
}

.balance {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.balance-box {
    border: 1px solid #F2A900;
    border-radius: 5px;
    padding: 5px;
}

.balance-text {
    margin: 0;
}


/* Main Content */

.main-content {
    background-color: #1c1b1a;
    min-height: 100vh;
    padding: 30px;
    display: flex;
    flex-direction: column;
    /* Updated to column layout */
    align-items: flex-start;
    /* Updated to align items on the left */
    gap: 20px;
    /* Add gap between elements */
}

.whitelist-mint,
.public-mint {
    width: 35%;
    background-color: transparent;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Updated to justify items on the left */
    align-items: flex-start;
    /* Updated to align items on the left */
    border: 2px solid #F2A900;
}

.mint-title {
    color: white;
    margin-bottom: 0px;
}

h2 {
    color: white;
    margin-bottom: 0px;
}

.mint-price {
    color: white;
    margin-top: 5px;
}

.progress-bar {
    width: 30%;
    height: 18px;
    background-color: #F2A900;
    border-radius: 5px;
    margin: 10px 0;
    overflow: hidden;
    border: 1px solid #F2A900;
    margin-bottom: 0px;
}

.progress-fill {
    width: 50.62%;
    height: 100%;
    background-color: #8c6200;
    border: 1px solid #F2A900;
}

.send-button,
.send-button-disabled {
    display: inline-block;
    padding: 5px 20px;
    margin-right: 10px;
    background-color: #F2A900;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.psend-button,
.psend-button-disabled {
    display: inline-block;
    padding: 5px 20px;
    margin-right: 10px;
    background-color: transparent;
    color: #F2A900;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    padding-top: 15px;
}

.send-button-disabled {
    background-color: grey;
    cursor: not-allowed;
}

.whitelisted-text {
    color: white;
}

.live-text {
    color: white;
}

.progress-text {
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
}

.supply-text {
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
}

.countdown-text {
    color: white;
}

.public-countdown-text {
    color: white;
}

.countdown {
    color: white;
}

.wl-countdown-text {
    color: white;
}

.qr-container {
    padding: 10px;
    display: flex;
    align-items: flex-start;
    background-color: #F2A900;
    padding-right: 20px;
    margin-right: 1px;
}

.qr-image-container {
    display: flex;
    /* Added */
    align-items: flex-start;
    /* Added */
    margin-right: 16px;
}

.qr-image {
    max-width: 200px;
    height: auto;
    padding: 5px;
}

.wallet-text {
    font-size: 14px;
    margin: 0;
}

.wallet-text-total {
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    padding-bottom: 20px;
}

.wallet-text-fin {
    font-size: 14px;
    margin: 0;
    padding-bottom: 2px;
}

.wallet-text-title {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    text-decoration: underline;
    padding-bottom: 5px;
}

.wlfee-title {
    color: white;
    text-decoration: underline;
    font-size: 13px;
    margin-bottom: 2px;
}

.wlfee-text {
    color: white;
    font-size: 12px;
    margin-bottom: 0px;
    margin-top: 0px;
}

.extra-button {
    display: inline-block;
    padding: 8px 20px;
    margin-right: 0px;
    background-color: transparent;
    color: #F2A900;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
}

.pubfee-text {
    color: black;
    margin-bottom: -10px;
    padding-bottom: 9px;
    margin-top: 5px;
    text-align: left;
    padding-left: 20px;
}

.pubfee-title {
    color: black;
    text-decoration: underline;
    font-weight: bold;
    padding-bottom: 0px;
    margin-bottom: 0px;
    text-align: left;
    padding-left: 20px;
}

.pubfeee-div {
    align-items: start;
}


/*.navtitle {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
} */

.navtext {
    font-size: larger;
    font-weight: bold;
    padding-right: 25px;
}